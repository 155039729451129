:root{
  --color1:#C28C04;
  --color2:#ffff; /**/
  --color3:#FCFFFC; 
  --color4:#ecf39e;
  --color5:#1b4357;
  --color6:#d70000b2;
  --color7: #0000008f;
  --color8: #175676;
  --color9:#2d3d19;
  --color10:#678d58;
  --color11:#283618;
  --color12:#8d6f23;
  --color13:#ee4266;
  --color14:#3bceac;
  --color15:#0ead69;
  --color16:#ffd23f;
  --color17:#540d6e;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modals{
  background-color: var(--color7);
}


.pageNumber{
  border: 1px solid var(--color1);
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedPageNumber{
  background-color: var(--color1);
  color: #fff;
}
.lastPage{
  border: none;
  cursor: not-allowed;
  border-radius: 100%;
}