@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@layer components{
  .label{
    @apply roboto mb-2 text-lg font-semibold inline-block text-gray-700
  }
  .input{
    @apply w-full border roboto focus:outline-none focus-within:shadow px-3 py-2 rounded placeholder:italic placeholder:text-sm
  }
  .text_area{
    @apply border roboto min-h-[6rem] rounded px-3 py-2 focus:outline-none focus-within:shadow placeholder:italic placeholder:text-sm w-full
  }
  .mandatory{
    @apply text-2xl text-red-800
  }
}
.roboto {
  font-family: 'Dancing Script', cursive;
  font-family: 'Raleway', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}



ul li.active{
  position: relative;
  color: var(--color1);
}

ul li.active::after{
  content: '';
  width: 100%;
  height: 4px;
  background-color: var(--color1);
  position: absolute;
  left: 0;
  bottom: -10px;
  border-radius: 10px;
}

.select-search-input {
  height: 42px !important;
}

.react-datepicker-popper[data-placement^=bottom]{
  z-index: 50;
}

.select-search-input{
  border: 1px solid #e5e5e5 !important;
}


/* Define the styles for the scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Change color on hover */
}

/* Define the color of the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Define the track (background) of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}